import { warningToast } from "toast-service!sofe";
import CodeMirror from "codemirror";
import "./spreadsheet-highlight.helper.js";
import "codemirror/addon/hint/show-hint.js";
import "./closebrackets.helper.js";
import "./matchbrackets.helper.js";
import "codemirror/addon/lint/lint.js";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/lib/codemirror.css";
import "./excel-formula.js";
import "codemirror/addon/search/search.js";
import "codemirror/addon/search/searchcursor.js";
import "codemirror/addon/search/jump-to-line.js";
import "codemirror/addon/dialog/dialog.js";
import "codemirror/addon/dialog/dialog.css";
import { format } from "./formatter";

import { getMethods, getBadFieldPositions } from "src/logic.helper.js";

let badFields = [];

export function setBadFields(_badFields) {
  badFields = _badFields;
}

CodeMirror.registerHelper("lint", "spreadsheet", function (text) {
  return getBadFieldPositions(text, badFields, CodeMirror);
});

CodeMirror.registerHelper("hint", "spreadsheet", (cm) => {
  CodeMirror.showHint(cm, CodeMirror.hint.spreadsheetAsync, { async: true });
});

CodeMirror.registerHelper("hint", "spreadsheetAsync", (cm, callback) => {
  let Pos = CodeMirror.Pos;
  let cur = cm.getCursor();
  let token = cm.getTokenAt(cur);

  let value = token.string;

  if (token.type === "bracket") {
    getMethods("")
      .then((resp) => {
        callback({
          from: Pos(cur.line, token.start),
          to: Pos(cur.line, token.end),
          list: [...resp],
        });
      })
      .catch((err) => {
        throw err;
      });
  } else {
    getMethods(value)
      .then((resp) => {
        callback({
          from: Pos(cur.line, token.start),
          to: Pos(cur.line, token.end),
          list: [...resp],
        });
      })
      .catch((err) => {
        throw err;
      });
  }
});

export function autoFormat(updateCode, cm) {
  try {
    let text = cm.getValue();
    const formattedText = format(text);
    cm.setValue(formattedText);
    updateCode(formattedText);
  } catch {
    warningToast("You entered an invalid expression", "I'm sorry");
  }
}
