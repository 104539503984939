export default function ({ origin, sofeManifest, name, rule, path, hash }) {
  return `
<!DOCTYPE html>
<html>
  <head>
    <meta charset="UTF-8">
    <title>SME Logic Editor</title>
    <script>
      window.initialValue = {
        rule: \`${rule || ""}\`,
        name: \`${name}\`,
        path: \`${path}\`,
      }
      window.location.hash = "${hash}";
      window.addEventListener(
        "message",
        function(event) {
          if (event.origin !== "${origin}" || event.data !== "logic-ui-window-setup")
            return;

          window.source = event.source;
          event.source.onunload = function() { window.close() };
        },
        false
      );
    </script>
    <link rel="shortcut icon" href="https://cdn.canopytax.com/images/canopy-favicon-new-green.jpg">
    <style type="text/css" media="all">
      @keyframes blink {
        0% {
          opacity: 1;
        }
        50% {
          opacity: .50;
        }
        100% {
          opacity: 1;
        }
      }
    </style>
    <script>
      window.sofeManifest = { sofe: ${JSON.stringify(sofeManifest)} };
    </script>
    <script src="${sofeManifest.manifest["common-dependencies"]}"></script>
    <script>
    // Load the styleguide
    SystemJs.import('canopy-styleguide!sofe');

    // We need to do this before loading any sofe services, so that overrides like "8080" work correctly
    SystemJs.import('canopy-sofe-extensions');

    SystemJs.import('logic-ui!sofe').then(logic => {
      document.getElementById('cp-main-content').innerHTML = "";
      logic.showDetachedLogicEditor();

      window.addEventListener("message", function(event) {
        if (
          event.origin !== "${origin}" ||
          (event.data && event.data.type === "webpackClose")
        )
          return;

        console.log("message", event.data);

        const data = JSON.parse(event.data);

        if (data.editLogic) {
          window.location.hash = data.hash;
          editRule(data.name, data.path, data.rule);
        }

        if (data.insertText) {
          logic.insertText(data.text);
        }
      });

      editRule(
        window.initialValue.name,
        window.initialValue.path,
        window.initialValue.rule
      );

      function editRule(name, path, rule) {
        logic.openLogicEditor(name, path, rule)
          .then(rule => {
            window.source.postMessage(JSON.stringify({
              newLogicRule: true,
              rule: rule,
            }), "${origin}")
          })
          .catch(error => setTimeout(() => {
            console.error('error editing rule', error);
            throw error;
          }));
      }
    }).catch(error => console.error(error));
  </script>
  </head>
  <body>
    <div id="cp-main-content">
      <div style="display: flex; flex-direction: column; text-align: center; margin-top: 100px;">
        <div style="animation: blink .5s linear 0s infinite alternate;">
          <img style="width: 330px;" src="https://cdn.canopytax.com/images/Canopy_C.svg" />
        </div>
      </div>
    </div>


  </body>
</html>
`;
}
